<template>
  <div id="footer">
    <div class="wrapper">
      <div class="left">
        <div class="Slogan">探索政策智慧 发现无限可能</div>
        <div>
          <h4><i class="icon iconfont icon-youqinglianjie"></i>友情链接：</h4>

          <ul>
            <li><a href="https://di.cqvip.com/" target="_blank">智启科创平台</a></li>
            <li><a href="https://super.cqvip.com/" target="_blank">科创助手</a></li>
          </ul>
        </div>
        <div>
          <h4><i class="icon iconfont icon-lianxiwomen1"></i>联系我们：</h4>
          <p>
            <span>地址：中国·重庆两江新区</span><span>邮编：400000</span
            ><span>电话：023-67033367（技术支持）</span
            ><span>023-63505712（业务支持）</span>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="code">
          <img src="@/assets/images/code.jpg" alt="" />
          微信扫码关注，政策资讯快人一步。
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="wrapper">
        <div>
          <p>
          COPYRIGHT © 2022 重庆维普资讯有限公司 保留所有权利
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">渝B2-20050021-1</a> 
          <a style="margin-left: 8px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002500403" target="_blank">渝公网备 50019002500403</a>
        </p>
        <p>建议使用360(极速模式)、firefox、谷歌浏览器</p>
        </div>
        <div class="right">
          <span class="sp">重庆市出版专项资金资助</span>
         
        </div>


      </div>
    </div>
    <el-dialog title="用户协议" :visible.sync="agreementVisible" width="900px">
      <VipAgreement />
    </el-dialog>
    <el-dialog title="隐私政策" :visible.sync="privacyVisible" width="900px">
      <VipPrivacy />
    </el-dialog>
  </div>
</template>
<script>
import VipAgreement from "@/components/agreement";
import VipPrivacy from "@/components/privacy";

export default {
  components: { VipAgreement, VipPrivacy },
  data() {
    return {
      privacyVisible: false,
      agreementVisible: false,
    };
  },
};
</script>
