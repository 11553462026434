import {
    Loginout
} from "@/utils/api";
var profileUrl_ = '';
var registerUrl_ = '';
if (process.env.NODE_ENV === 'production') {
    profileUrl_ = process.env.VUE_APP_USERPROFILE_URL + "/user/profile"
}
else if (process.env.NODE_ENV === 'testing') {
    profileUrl_ = process.env.VUE_APP_USERPROFILE_URL + "/user/profile"
}
else {
    profileUrl_ = process.env.VUE_APP_USERPROFILE_URL + "/user/profile"
}

if (process.env.NODE_ENV === 'production') {
    registerUrl_ = process.env.VUE_APP_SSO_URL + "/login?service=" + process.env.VUE_APP_DOMAIN_URL + "/User/Login&type=register"
}
else if (process.env.NODE_ENV === 'testing') {
    registerUrl_ = process.env.VUE_APP_SSO_URL + "/login?service=" + process.env.VUE_APP_DOMAIN_URL + "/User/Login&type=register"
}
else {
    registerUrl_ = process.env.VUE_APP_SSO_URL + "/login?service=" + process.env.VUE_APP_DOMAIN_URL + "/User/Login&type=register"
}

export const configSSOLogin = {
    //service: "http://192.168.20.27:8080/User/Login",
    service: process.env.VUE_APP_DOMAIN_URL + "/User/Login",
    // service: "https://zc.cqvip.com/User/Login",
    //homeService: "http:/192.168.20.27:8080/",
    //homeService: "http://192.168.30.115:8147/",
    loginUrl: process.env.VUE_APP_SSO_URL + "/login",


    // profileUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_USERPROFILE_URL + "/user/profile" : process.env.VUE_APP_USERPROFILE_URL + ":8902/user/profile",
    logoutUrl: process.env.VUE_APP_SSO_URL + "/logout",
    // registerUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_SSO_URL + "/login?service=" + process.env.VUE_APP_DOMAIN_URL + "/User/Login&type=register" : process.env.VUE_APP_SSO_URL + "/login?service=" + process.env.VUE_APP_DOMAIN_URL + "/User/Login&type=register"
    profileUrl: profileUrl_,
    registerUrl: registerUrl_,

    // loginUrl: "https://ccsso.cqvip.com/login",
    // profileUrl: "https://cc.cqvip.com/user/profile",
    // logoutUrl: "https://ccsso.cqvip.com/logout",
    // registerUrl: "https://cc.cqvip.com/login?type=register"
}

export const redirectSSOLogin = () => {
    location.href = configSSOLogin.loginUrl + '?service=' + configSSOLogin.service;
}

export const redirectSSORegister = () => {
    location.href = configSSOLogin.registerUrl;
}

export const redirectSSOUserIndex = () => {
    window.open(configSSOLogin.profileUrl);
}

export const handleSSOLoginOut = () => {
    Loginout().then((res) => {
        if (res.data.apiStatusCode == 1000) {
            localStorage.removeItem("_login");
            localStorage.removeItem("_user");
            localStorage.removeItem("_tokenKey");
            localStorage.removeItem("_refreshTokenKey");
            localStorage.removeItem("_MKA");
            localStorage.removeItem("_UIF");

            location.href = configSSOLogin.logoutUrl + '?service=' + configSSOLogin.service;
        }
    });
}

export const handleSSOLoginOutOrg = () => {
    Loginout().then((res) => {
        console.log(configSSOLogin.logoutUrl + '?service=' + configSSOLogin.service);
        if (res.data.apiStatusCode == 1000) {
            localStorage.removeItem("_login");
            localStorage.removeItem("_user");
            localStorage.removeItem("_tokenKey");
            localStorage.removeItem("_refreshTokenKey");
            localStorage.removeItem("_MKA");
            localStorage.removeItem("_UIF");

            location.href = configSSOLogin.logoutUrl + '?service=' + configSSOLogin.service;
        }
    });
}